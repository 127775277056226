@font-face {
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/inter-400-regular.woff2") format("woff2"),
    url("./fonts/inter-400-regular.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/inter-500.woff2") format("woff2"),
    url("./fonts/inter-500.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/roboto-400-regular.woff2") format("woff2"),
    url("./fonts/roboto-400-regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/roboto-500.woff2") format("woff2"),
    url("./fonts/roboto-500.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
  src: url("./fonts/roboto-700.woff2") format("woff2"),
    url("./fonts/roboto-700.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  font-style: normal;
  src: url("./fonts/roboto-900.woff2") format("woff2"),
    url("./fonts/roboto-900.woff") format("woff");
}

@font-face {
  font-family: "Roboto-Slab";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/roboto-slab-400-regular.woff2") format("woff2"),
    url("./fonts/roboto-slab-400-regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto-Slab";
  font-weight: 700;
  font-style: normal;
  src: url("./fonts/roboto-slab-700.woff2") format("woff2"),
    url("./fonts/roboto-slab-700.woff") format("woff");
}
