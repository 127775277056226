.cards-section-title {
  font-family: "Roboto-Slab", serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 46px;
  color: #1a1b22;
  margin: 0;
  padding: 0;
  padding-top: 80px;
  margin: auto;
  max-width: 1250px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin: 0 auto;
  max-width: 1252px;
  padding-top: 64px;

  &_saved {
    justify-content: flex-start;
    padding-bottom: 32px;
  }

  &__view-more-container {
    margin: 64px 0 80px 0;
    display: flex;
    justify-content: center;
    gap: 50px;
  }

  &__view-more {
    width: 288px;
    height: 64px;
    border-radius: 80px;
    background-color: #e8e8e8;
    border: none;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;

    &:hover {
      background-color: #d8d8d8;
    }
  }
}

.card {
  width: 400px;
  height: 576px;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  list-style: none;

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 352px;
    margin: auto;
    gap: 12px;
  }

  &__image {
    z-index: 0;
    width: 100%;
    height: 272px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__date {
    color: #b6bcbf;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    padding-top: 20px;
  }

  &__title {
    font-family: "Roboto-Slab", serif;
    height: 90px;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #1a1b22;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }

  &__text {
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    width: 352px;
    height: 88px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
  }

  &__link {
    color: #000;
    text-decoration: none;
  }

  &__publisher {
    font-family: "Roboto-Slab", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #b6bcbf;
    margin: 0;
  }

  &__trash-button {
    border: 0;
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 2;
    background-image: url(../../images/trash.svg);
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      background-image: url(../../images/trash_hover.svg);
    }
  }

  &__save-button {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 2;
    background-color: #fff;
    background-image: url("../../images/bookmark.svg");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;

    &:hover {
      cursor: pointer;
      background-image: url(../../images/bookmark_hover.svg);
    }
  }

  &__save-button_saved {
    background-image: url(../../images/bookmark_filled.svg);
  }

  &__keyword-button {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    position: absolute;
    top: 24px;
    left: 24px;
    border-radius: 10px;
    height: 40px;
    padding: 0 22px;
    text-transform: capitalize;
    border: 0;
    display: none;
  }

  &__hover-text {
    display: none;
    background-color: #fff;
    position: absolute;
    top: 24px;
    right: 69px;
    z-index: 2;
    width: 182px;
    height: 40px;
    border-radius: 10px;
    border: 0;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 11.72px;

    &_active {
      display: block;
    }
  }

  &__keyword-container {
    position: absolute;
    background-color: white;
    display: flex;
    align-items: center;
    width: auto;
    height: 40px;
    border-radius: 10px;
    padding: 0 14px;
    top: 24px;
    left: 24px;
  }
}

$breakpoint-tablet: 1255px;
$breakpoint-mobile: 740px;

@media screen and (max-width: $breakpoint-tablet) {
  .cards-section-title {
    padding-left: 40px;
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    margin: 0 auto;
    max-width: 1252px;
    padding-top: 64px;

    &__view-more-container {
      margin: 64px 0 80px 0;
      display: flex;
      justify-content: center;
      gap: 50px;
    }
  }

  .card {
    width: 224px;
    height: 420px;
    margin-bottom: 24px;
    max-width: 352px;

    &__container {
      max-width: 192px;
    }

    &__image {
      height: 150px;
    }

    &__date {
      padding-top: 16px;
      width: 199px;
    }

    &__title {
      font-size: 26px;
      line-height: 30px;
      width: 192px;
      height: 96px;
    }

    &__text {
      width: 100%;
      height: 66px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .cards-section-title {
    font-size: 30px;
    line-height: 34px;
    margin: auto;
    padding: 0;
    padding-top: 32px;
    max-width: 288px;
  }

  .cards-container {
    flex-direction: column;
    align-items: center;
    width: 95vw;
    gap: 10px;
    padding-top: 24px;

    &__view-more-container {
      flex-direction: column;
      gap: 30px;
      margin: 21px 0 24px 0;
    }
  }

  .card {
    height: 440px;
    margin-bottom: 24px;
    width: 288px;
    margin: 0;

    &__container {
      max-width: 256px;
    }

    &__image {
      height: 196px;
    }

    &__date {
      padding-top: 16px;
    }

    &__title {
      font-size: 22px;
      line-height: 24px;
      width: 256px;
      height: 48px;
    }

    &__text {
      height: 88px;
      width: 256px;
    }
  }
}
