.about {
  height: 624px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90%;
  }

  &__avatar {
    width: 464px;
    height: auto;
    border-radius: 50%;
    margin-right: 50px;
  }

  &__info {
    width: 600px;
    align-self: baseline;
  }

  &__title {
    font-family: "Roboto-Slab", serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 24px;
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 0px;
  }
}

$breakpoint-tablet: 768px;
$breakpoint-mobile: 500px;

@media (max-width: $breakpoint-tablet) {
  .about {
    height: 354px;

    &__avatar {
      width: 232px;
    }

    &__title {
      font-size: 30px;
      line-height: 40px;
      margin-top: 0px;
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 705px) {
  .about {
    height: 100%;
  }
}

@media (max-width: $breakpoint-mobile) {
  .about {
    height: 752px;
    flex-direction: column;
    justify-content: flex-start;

    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__avatar {
      width: 272px;
      margin: auto;
      margin-top: 32px;
    }

    &__info {
      width: 100%;
    }

    &__title {
      font-size: 30px;
      line-height: 40px;
      margin-top: 24px;
      margin-bottom: 16px;
    }

    &__text {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
