.preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding-top: 80px;

  &__circle {
    display: block;
    width: 50px;
    height: 50px;
    border: 4px solid #444;
    border-bottom-color: #888;
    border-radius: 50%;
    animation: spin 0.75s infinite linear;
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #b6bcbf;
    line-height: 24px;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
