.search-form {
  padding-top: 64px;
  position: relative;
  &__input {
    width: 608px;
    padding: 21px;
    border-radius: 100px;
    border: none;
    font-size: 16px;
  }

  &__button {
    position: absolute;
    bottom: -1%;
    right: -8%;
    height: 65px;
    width: 170px;
    border-radius: 100px;
    background-color: #2f71e5;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: white;
    border: none;
    cursor: pointer;
  }
}

$breakpoint-tablet: 768px;
$breakpoint-mobile: 500px;

@media screen and (max-width: $breakpoint-tablet) {
  .search-form {
    padding-top: 48px;
    &__input {
      width: 440px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .search-form {
    padding-top: 98px;
    &__input {
      width: 80vw;
    }
    &__button {
      position: relative;
      bottom: -16px;
      right: 0;
      width: 90vw;
      height: 56px;
    }
  }
}
